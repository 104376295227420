<template>
  <div>
    <h1 class="Title" v-html="$t('statut.titel')"> </h1>
    <br>
    <br>
    <h1 v-html="$t('statut.t1')"> </h1>
    <p v-html="$t('statut.t1p1')"> </p>
    <h2> §2 </h2>
    <p v-html="$t('statut.t1p2_1')"> </p>
    <p v-html="$t('statut.t1p2_2')"> </p>
    <p v-html="$t('statut.t1p2_3')"> </p>
    <p v-html="$t('statut.t1p2_4')"> </p>
    <p v-html="$t('statut.t1p2_5')"> </p>
    <p v-html="$t('statut.t1p2_6')"> </p>
    <p v-html="$t('statut.t1p2_7')"> </p>
    <h2> §3 </h2>
    <p v-html="$t('statut.t1p3')"> </p>
    <h1 v-html="$t('statut.t2')"> </h1>
    <p v-html="$t('statut.t2p0')"> </p>
    <h2> §1 </h2>
    <p v-html="$t('statut.t2p1_1')"> </p>
    <p v-html="$t('statut.t2p1_2')"> </p>
    <h2> §2 </h2>
    <p v-html="$t('statut.t2p2_1')"> </p>
    <h2> §3 </h2>
    <p v-html="$t('statut.t2p3_1')"> </p>
    <p v-html="$t('statut.t2p3_2')"> </p>
    <p v-html="$t('statut.t2p3_3')"> </p>
    <p v-html="$t('statut.t2p3_4')"> </p>
    <h2> §4 </h2>
    <p v-html="$t('statut.t2p4_1')"> </p>
    <p v-html="$t('statut.t2p4_2')"> </p>
    <p v-html="$t('statut.t2p4_3')"> </p>
    <p v-html="$t('statut.t2p4_4')"> </p>
    <p v-html="$t('statut.t2p4_5')"> </p>
  </div>
</template>

<script>
export default {
  name: "Statuut_Bevoegdheden"
}
</script>

<style scoped>

.Title {
  font-size: 250%;
}

/* Only on really small screens */
@media only screen and (max-width: 960px) {
  div {
    margin: 2% 10% 3%;
  }
}

/* Only on larger screens */
@media only screen and (min-width: 601px) {
  div {
    margin: 2% 30% 3%;
  }
}

div p, div h2, div h3, div h1 {
  text-align: justify;
  text-justify: inter-word;
  font-size: 140%;
}

</style>